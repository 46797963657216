import "../Styles/footer.css";
import location from "../Assests/location1.svg";
import mail from "../Assests/Mail1.svg"
import phone  from "../Assests/phone.svg"
import logo from "../Assests/sg-logo-1.svg"
import logo2 from "../Assests/Phoenix Colored 2.png"

const Footer = () => {
  return (
  
        <footer className="text-center text-lg-start footerclass">
          {/* Section: Social media */}
          <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
            {/* Left */}
           
            <div className="col-lg-4 ftlocation_1 d-none d-lg-block">
              <span>
              <img className="fticon"  src={location} alt="" />
              <span className="ms-3 locationwrd">Sripreamadur, INDIA</span>
              </span>
            </div>

            <div className="col-lg-4 ftlocation_2 d-none d-lg-block">
            <span>
              <img className="fticon"  src={mail} alt="" />
              <span className="ms-3 mailwrd">valuechain.saint-gobain.com</span>
              </span>
            </div>

            <div className="col-lg-4 ftlocation_3 d-none d-lg-block">
            <span>
              <img className="fticon"  src={phone} alt="" />
              <span className="ms-3 phonewrd">1800 102 1215</span>
              </span>
            </div>

          
            {/* Left */}
    
            {/* Right */}
            <div>
              {/* <a href="#" className="me-4 text-reset">
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a href="#" className="me-4 text-reset">
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a href="#" className="me-4 text-reset">
                <FontAwesomeIcon icon={faGoogle} />
              </a>
              <a href="#" className="me-4 text-reset">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a href="#" className="me-4 text-reset">
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
              <a href="#" className="me-4 text-reset">
                <FontAwesomeIcon icon={faGithub} />
              </a> */}
            </div>
            {/* Right */}
          </section>
          {/* Section: Social media */}
    
          {/* Section: Links  */}
          <section>
            <div className="container text-center text-md-start mt-5 text-light">
              {/* Grid row */}
              <div className="row mt-3">
                {/* Grid column */}
                <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                  {/* Content */}
                  <h2 className="text-uppercase fw-bold mb-4">
                    {/* <FontAwesomeIcon icon={faGem} className="me-3" /> */}
                   PHOENIX
                  </h2>
                  <p className="phoenixftpara">
                  Unveiling Phoenix, the next-generation integrated platform for Saint-Gobain India! Built on a future-proof tech stack, Phoenix seamlessly streamlines your entire value chain.  This intuitive,  platform empowers informed decision-making at all levels by delivering actionable insights to every stakeholder. 
                  </p>
                </div>
                {/* Grid column */}
    
                {/* Grid column */}
                <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                  {/* Links */}
                  <h6 className="text-uppercase fw-bold mb-4">
                  <span className="oursol">Our Solutions</span>
                  </h6>
                  <p>
                    <a href="#!" className="text-reset link">Weber</a>
                  </p>
                  <p>
                    <a href="#!" className="text-reset link ">GIM</a>
                  </p>
                  <p>
                    <a href="#!" className="text-reset link">Glass</a>
                  </p>
                  <p>
                    <a href="#!" className="text-reset link">Solar Glass</a>
                  </p>
                  <p>
                    <a href="#!" className="text-reset link">Solutions</a>
                  </p>
                  <p>
                    <a href="#!" className="text-reset link">Truck Management</a>
                  </p>
                </div>
                {/* Grid column */}
    
                {/* Grid column */}
                <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                  {/* Links */}
                  <h6 className="text-uppercase fw-bold mb-4">
                  <span className="ftquick">Quick Links</span>
                   </h6>
                  <p>
                    <a href="#!" className="text-reset link">Whats New</a>
                  </p>
                  <p>
                    <a href="#!" className="text-reset link">About us</a>
                  </p>
                  <p>
                    <a href="#!" className="text-reset link">Modules</a>
                  </p>
                  <p>
                    <a href="#!" className="text-reset link">Our Customers</a>
                  </p>
                  <p>
                    <a href="#!" className="text-reset link">Our People</a>
                  </p>
                </div>
                {/* Grid column */}
    
                {/* Grid column */}
                <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                  {/* Links */}
                  {/* <h6 className="text-uppercase fw-bold mb-4">Contact</h6> */}
                  {/* <p><FontAwesomeIcon icon={faHome} className="me-3" /> New York, NY 10012, US</p> */}
                  
                 
                  <img className="ftlogo" src={logo} alt="" />
                  <img className="ftlogo2 mt-4" src={logo2} alt="" />
                  {/* <p><FontAwesomeIcon icon={faPhone} className="me-3" /> + 01 234 567 88</p> */}
                  {/* <p><FontAwesomeIcon icon={faPrint} className="me-3" /> + 01 234 567 89</p> */}
                </div>
                {/* Grid column */}
              </div>
              {/* Grid row */}
            </div>
          </section>
          {/* Section: Links  */}
    
          {/* Copyright */}
          
          <div className="text-center p-4 text-light" style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
            © 2024 Copyright:
            <a className="text-light fw-bold ftphoenixname" href="">Phoenix</a>
          </div>
          {/* Copyright */}
        </footer>
      );
    };
    
    export default Footer;
