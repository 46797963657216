import React from "react";
import Slider from "react-slick";
import carouselbg from "../Assests/carouselimg1.png";
import partner1 from "../Assests/partner1.png"
import partner2 from "../Assests/partner2.png"
import partner3 from "../Assests/partner3.png"
import partner4 from "../Assests/partner4.png"
import partner5 from "../Assests/partner5.png"
import partner6 from "../Assests/partner6.png"
import partner7 from "../Assests/partner7.png"
import partner8 from "../Assests/partner8.png"
import partner9 from "../Assests/partner9.png"
import "../Styles/partners.css";






const Partners = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 2000,
        slidesToShow: 2.3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0, 
        cssEase: 'linear', 
        swipeToSlide: true,
        rtl: true, 
        prevArrow: <></>,
        nextArrow: <></>,
    };
  
    return (
      <div className="container-fluid w-100  mt-5">
        <div className="row">
            <div className="col-lg-12 ">
            <h1 className="parth1">Over 15 + Partners Like Glass, Weber, Sg Home <br />
                     Used and Benefited Faster With PHOENIX.</h1>
                  <Slider className="partner" {...settings}>
                <div className="pimg">
                  <img className="p1" src={partner1} alt="" />
                </div>
                <div className="">
                  <img className="p2" src={partner2} alt="" />
                </div>
                <div className="">
                  <img className="p3" src={partner3} alt="" />
                </div>
                <div className="">
                  <img className="p4" src={partner4} alt="" />
                </div>
                <div className="">
                  <img className="p5" src={partner5} alt="" />
                </div>
                <div className="">
                  <img className="p6" src={partner6} alt="" />
                </div>
                <div className="">
                  <img className="p7" src={partner7} alt="" />
                </div>
                <div className="">
                  <img className="p8" src={partner8} alt="" />
                </div>
                <div className="">
                  <img className="p9" src={partner9} alt="" />
                </div>
              </Slider>
              </div>
                  </div>
              </div>
        
 
    );
  };
  
  export default Partners;