import "../Styles/modules.css";
import Msub1 from "../Assests/Modulessub_1.png";
import Msub2 from "../Assests/Modulessub_2.png";
import Msub3 from "../Assests/Modulessub_3.png";
import Msub4 from "../Assests/Modulessub_4.png";
import Msub5 from "../Assests/Modulessub_5.png";
import Msub6 from "../Assests/Modulessub_6.png";
import Msub7 from "../Assests/Modulessub_7.png";
import Msub8 from "../Assests/Modulessub_8.png";
import arrow from "../Assests/right_arrow.svg";
import module1 from "../Assests/module-1.png";

const Modules = () => {
  return (
    <div className="container p-2 containeroverallmodule">
      <div className="row">
        <div className="col-lg-6 containermod1 mt-3">
          <h2 className="modhead">Our Module & Solutions</h2>
          {/* module 1 card 1 */}
          <div className="card modcrd1 mt-5">
            <div className="card-body crdimg1">
              <img className="msb1" src={Msub1} alt="" />
              <h2 className="mh1 mt-4">Warehouse</h2>
              <p className=" col-lg-10 mp1">
                Our factories uphold the top world standards, responsible
                towards environment.
              </p>
              {/* <div className="col-lg-4"></div> */}
              <div className="col-lg-12 d-flex justify-content-end">
                <div className="col-lg-4">
                  <a href="#" className="omb1">
                    <p className="mb1 text-center">
                      Know More
                      <span className="mic1">
                        <img className="" src={arrow} alt="" />
                      </span>
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* module 1 card 1 */}

          {/* module 2 card 2 */}
          <div className="card modcrd1 mt-5">
            <div className="card-body crdimg2">
              <img className="msb1" src={Msub2} alt="" />
              <h2 className="mh1 mt-4">Product Management</h2>
              <p className=" col-lg-10 mp1">
                Our factories uphold the top world standards, responsible
                towards environment.
              </p>
              {/* <div className="col-lg-4"></div> */}
              <div className="col-lg-12 d-flex justify-content-end">
                <div className="col-lg-4">
                  <a className="omb1" href="">
                    <p className="mb1 text-center">
                      Know More
                      <span className="mic1">
                        <img className="" src={arrow} alt="" />
                      </span>
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* module 2 card 2 */}

          {/* module 3 card 3 */}
          <div className="card modcrd1 mt-5">
            <div className="card-body crdimg3">
              <img className="msb1" src={Msub3} alt="" />
              <h2 className="mh1 mt-4">Transport Management</h2>
              <p className=" col-lg-10 mp1">
                Our factories uphold the top world standards, responsible
                towards environment.
              </p>
              {/* <div className="col-lg-4"></div> */}
              <div className="col-lg-12 d-flex justify-content-end">
                <div className="col-lg-4">
                  <a className="omb1" href="">
                    <p className="mb1 text-center">
                      Know More
                      <span className="mic1">
                        <img className="" src={arrow} alt="" />
                      </span>
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* module 3 card 3 */}

          {/* module 4 card 4 */}
          <div className="card modcrd1 mt-5">
            <div className="card-body crdimg4">
              <img className="msb1" src={Msub4} alt="" />
              <h2 className="mh1 mt-4">Visual Warehouse</h2>
              <p className=" col-lg-10 mp1">
                Our factories uphold the top world standards, responsible
                towards environment.
              </p>
              {/* <div className="col-lg-4"></div> */}
              <div className="col-lg-12 d-flex justify-content-end">
                <div className="col-lg-4">
                  <a className="omb1" href="">
                    <p className="mb1 text-center">
                      Know More
                      <span className="mic1">
                        <img className="" src={arrow} alt="" />
                      </span>
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* module 4 card 4 */}
        </div>

        <div className="col-lg-6 containermod2">
          {/* module 1 card 1 */}
          <div className="card modcrd1 mt-5">
            <div className="card-body crdimg5">
              <img className="msb1" src={Msub5} alt="" />
              <h2 className="mh1 mt-4">Material Accounting</h2>
              <p className=" col-lg-10 mp1">
                Our factories uphold the top world standards, responsible
                towards environment.
              </p>
              {/* <div className="col-lg-4"></div> */}
              <div className="col-lg-12 d-flex justify-content-end">
                <div className="col-lg-4">
                  <a className="omb1" href="">
                    <p className="mb1 text-center">
                      Know More
                      <span className="mic1">
                        <img className="" src={arrow} alt="" />
                      </span>
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* module 1 card 1 */}

          {/* module 2 card 2 */}
          <div className="card modcrd1 mt-5">
            <div className="card-body crdimg6">
              <img className="msb1" src={Msub6} alt="" />
              <h2 className="mh1 mt-4">Reporting</h2>
              <p className=" col-lg-10 mp1">
                Our factories uphold the top world standards, responsible
                towards environment.
              </p>
              {/* <div className="col-lg-4"></div> */}
              <div className="col-lg-12 d-flex justify-content-end">
                <div className="col-lg-4">
                  <a className="omb1" href="">
                    <p className="mb1 text-center">
                      Know More
                      <span className="mic1">
                        <img className="" src={arrow} alt="" />
                      </span>
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* module 2 card 2 */}

          {/* module 3 card 3 */}
          <div className="card modcrd1 mt-5">
            <div className="card-body crdimg7">
              <img className="msb1" src={Msub7} alt="" />
              <h2 className="mh1 mt-4">Dashboard</h2>
              <p className=" col-lg-10 mp1">
                Our factories uphold the top world standards, responsible
                towards environment.
              </p>
              {/* <div className="col-lg-4"></div> */}
              <div className="col-lg-12 d-flex justify-content-end">
                <div className="col-lg-4">
                  <a className="omb1" href="">
                    <p className="mb1 text-center">
                      Know More
                      <span className="mic1">
                        <img className="" src={arrow} alt="" />
                      </span>
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* module 3 card 3 */}

          {/* module 4 card 4 */}
          <div className="card modcrd1 mt-5">
            <div className="card-body crdimg8">
              <img className="msb1" src={Msub8} alt="" />
              <h2 className="mh1 mt-4">Shipping</h2>
              <p className=" col-lg-10 mp1">
                Our factories uphold the top world standards, responsible
                towards environment.
              </p>
              {/* <div className="col-lg-4"></div> */}
              <div className="col-lg-12 d-flex justify-content-end">
                <div className="col-lg-4">
                  <a className="omb1" href="">
                    <p className="mb1 text-center">
                      Know More
                      <span className="mic1">
                        <img className="" src={arrow} alt="" />
                      </span>
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* module 4 card 4 */}
        </div>
      </div>
    </div>
  );
};

export default Modules;
