import React from "react";
import Slider from "react-slick";
import carouselbg from "../Assests/carouselimg1.png";
import arrow from "../Assests/right_arrow.svg";
import "../Styles/carousel.css";


const Carousel = () => {    
  const settings = {
    dots: true,
    infinite: true,
    navs: true,
    speed: 500,
    slidesToShow: 1.9,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    swipeToSlide: true,
    rtl: true,
    centerMode: true,
    prevArrow: <></>, // Custom prev arrow
    nextArrow: <></>, // Custom next arrow
  };

  return (
    <div className="container-fluid mb-5">
      <div className="row p-0">
        <div className="col-lg-12 w-100">
          <div className="card">
            <div className="card-body p-5 carousbg">
              <div className="row">
              <div className="col-lg-6">
              <h1 className="carh1 mb-5 ">
                Unveil the Possibilities <br /> with Phoenix
              </h1>
              </div>
              <div className="col-lg-3"></div>
              <div className="col-lg-3 wid d-flex justify-content-end" >
                <span>
                  <a className="prjob" href="">
                    <p className="pb1 text-center">
                      Know More
                      <span className="pic1">
                        <img className="" src={arrow} alt="" />
                      </span>
                    </p>
                  </a>
                </span>
              </div>
              </div>

              <Slider {...settings}>
                <div className="carh3">
                  <img className="carimg1" src={carouselbg} alt="" />
                  <h1></h1>
                </div>
                <div className="carh3">
                  <img className="carimg1" src={carouselbg} alt="" />
                </div>
                <div className="carh3">
                  <img className="carimg1" src={carouselbg} alt="" />
                </div>
                <div className="carh3">
                  <img className="carimg1" src={carouselbg} alt="" />
                </div>
                <div className="carh3">
                  <img className="carimg1" src={carouselbg} alt="" />
                </div>
                <div className="carh3">
                  <img className="carimg1" src={carouselbg} alt="" />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
