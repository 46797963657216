import react from "react";
import ware1 from "../Assests/ware1.png";
import ware2 from "../Assests/ware2.png";
import ware3 from "../Assests/ware3.png";
import arrow from "../Assests/right_arrow.svg";
import war3 from "../Assests/ware3icons.svg";
import warearrow from "../Assests/warearrow.png";
import "../Styles/warhouse.css";

const Warhouse = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-xs-6 col-xl-6 col-xxl-6 col-lg-6 col-md-6 col-sm-6 mt-5">
          <div class="card bgimg1 ">
            <div class="card-body1 ">
              <h3 class="wareboxtxt1">
                Multi-Warehouse <br /> Management System
              </h3>
              <a href="#" class="card-link">
                <img className="arrbtn1" src={arrow} alt="" />
              </a>
            </div>
          </div>

          <div class="card bgimg2 mt-5">
            <div class="card-body2">
              <h6 class="wareboxtxt2">
                Inter-warehouse
                <br /> Management System
              </h6>
              <a href="#" class="card-link">
                <img className="arrbtn2" src={arrow} alt="" />
              </a>
            </div>
          </div>
        </div>
        <div className="col-xs-6 col-xl-6 col-xxl-6 col-lg-6 col-md-6 col-sm-6">
          <div class="card img-fluid bgimg3 mt-5">
            <div class="card-body3">
              <h3 class="wareboxtxt3">
                Effective-Warehouse <br /> Management System
              </h3>
              <a href="#" class="card-link">
                <img className="arrbtn3" src={arrow} alt="" />
              </a>
            </div>
          </div>
          <h3 className="warepara mt-4">
            Manage Multiple <br /> Warehouses Globally
          </h3>
          <p className="waresubpara mt-3">
            Enable effective collaboration while keeping your data secure.
            Customize access for each team member so that they can view and
            change only the warehouse data they need.
          </p>
          <br />
          <a href="#" class="card-link wareexp ">
            <span className="">Explore more</span>
            <img className="exparr" src={arrow} alt="" />
          </a>
          <img className="ware3icn" src={war3} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Warhouse;
