import react from "react";
import arrow from '../Assests/right_arrow.svg'
import '../Styles/navbar.css'


const Navbar = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 navposition">
          <div className="navbar d-flex justify-content-around">
            
            
            <a className="li ms-5" href="#">
            Whats new
            </a>
            <a className="li" href="#">
              About
            </a>
            <a className="li" href="#">
            Modules
            </a>
            <a className="li" href="#">
            Our customer
            </a>
            <a className="li" href="#">
            Our People
            </a>
            <a className="li" href="#">
              <span className="button1 me-5">Signup 
              <span><img src={arrow} alt="" className="signarow ms-2"/></span>
            </span>
            </a>
            
       
          </div>
          </div>
       
      </div>
    </div>
  );
};

export default Navbar;