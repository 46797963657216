import react from "react";
import banner from '../Assests/Heroimg.gif'
import arrow from '../Assests/right_arrow.svg'
import videoic from '../Assests/Video_icon.svg'
import '../Styles/hero.css'


const Navbar = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12 p-0">
          <div className="card herocrd">
        <div className="card-body herobg ">
              <h4 className="heroh1 text-light">-Welcome to Phoenix</h4>
              <h1 className="herotitle">
              The Future Of <br />Logistics And Warehouse
              </h1>
              
              <div className="col-lg-12 col-sm-12 col-md-12 herobuttons">
              <button type="button" class="hob1 btn-primary">
                Watch Video
                <span className="watchvideo"><img className="videoicon" src={videoic} alt="" /></span>
                </button>
              
              <button type="button" class="hob2 btn-primary">
                Signup for Demo
                <span className="signarrow"><img src={arrow} alt="" /></span>
                </button>
              </div>
           
            </div>
          </div>
          </div>
      </div>
    </div>
  );
};

export default Navbar;


{/* <div className="leftbar"></div>
<h6 className="welcm ">-Welcome to Phoenix</h6>
<h1 className="Hd1 ">The Future of <br/>Logistics And Warehouse</h1>

<a className="" href="#">
  <span className="hb1">Watch Video 
  <span><img src={videoic} alt="" className="watchicon ms-2"/></span>
</span>
</a>

<a className="" href="#">
  <span className="hb2">Signup For Demo 
  <span><img src={arrow} alt="" className="signarow ms-2"/></span>
</span>
</a>

<img src={banner} alt="" className="heroimg"/> */}