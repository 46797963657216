import react from "react";
import logo from "../Assests/Plogo.png"
import sglogo from "../Assests/sg-logo-1.svg";
import location from "../Assests/prenavLocationicn1.svg";
import mail from "../Assests/prenavMailicn2.svg";
import "../Styles/Preenav.css";

const Preenav = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-3 col-md-5 col-sm-7 p-0 ">
          <div className="card phoenixlogocard">
            <div className="card-body phoenixlogocrd ">
              <img className="phoenixlogo" src={logo} alt="" />
            </div>
          </div>
        </div>
        <div className="col-lg-9 col-md-7 col-sm-5 p-0">
          <div className="card saingobainlogocard1">
            <div className="card-body saintgobainlogocrd p-0 d-flex justify-content-between">
              <img className="saintgobainlogo" src={sglogo} alt="" />
                <span className="toptext ms-5"><span className="">
                    <img className="locicn" src={location} alt="" />
                    </span>
                    <h6 className="locationtxt"> Sriperumbudur </h6>
                    </span>


                 <span className="toptext me-5 mb-2"><span className="">
                    <img className="mailicn  " src={mail} alt="" />
                    </span>
                    <h6 className="mailtxt"> Valuechain.saint-gobain.com </h6>
                    </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preenav;
