import "../Styles/team.css";
import mark1 from "../Assests/Qotes_1.png"
import mark2 from "../Assests/Qotes_2.png"
import model1 from "../Assests/weberprojimg-1.png";
import { Carousel } from 'react-bootstrap';

const Modules = () => {
  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-lg-12 mt-5">
          <h1 className="text-center teamh1">
            <span>
              <img className="mark_1" src={mark1} alt="" />
            </span>
            Team Synergy and Innovation</h1>
          <p className="teamp1 text-center">
            "Our team combines creativity, expertise, and dedication. Together,
            we tackle challenges <br />
            with enthusiasm, turning ideas into impactful solutions. United by a
            shared vision, <br />
            we push boundaries and drive progress in every project."
             <span>
              <img className="mark_2" src={mark2} alt="" />
            </span>
          </p>
        </div>
        <Carousel  controls={false} interval={3000} pause={false} fade>
        <Carousel.Item>
        <div className="row">
          <div className="col-lg-4 mt-5">
            <div class="card">
              <img class="card-img-top" src={model1} alt="Card image cap" />
              <div class="card-body model1name">
                <h5 class="card-title text-center text-light mt-2">
                  Card title 1
                </h5>
              </div>
            </div>

            <div class="card mt-5">
              <img class="card-img-top" src={model1} alt="Card image cap" />
              <div class="card-body model1name">
                <h5 class="card-title text-center text-light mt-2">
                  Card title 2
                </h5>
              </div>
            </div>
          </div>

          <div className="col-lg-4 mt-5">
            <div class="card">
              <img class="card-img-top" src={model1} alt="Card image cap" />
              <div class="card-body model1name">
                <h5 class="card-title text-center text-light mt-2">
                  Card title 3
                </h5>
              </div>
            </div>

            <div class="card mt-5">
              <img class="card-img-top" src={model1} alt="Card image cap" />
              <div class="card-body model1name">
                <h5 class="card-title text-center text-light mt-2">
                  Card title 4
                </h5>
              </div>
            </div>
          </div>

          <div className="col-lg-4 mt-5">
            <div class="card">
              <img class="card-img-top" src={model1} alt="Card image cap" />
              <div class="card-body model1name">
                <h5 class="card-title text-center text-light mt-2">
                  Card title 5
                </h5>
              </div>
            </div>

            <div class="card mt-5">
              <img class="card-img-top" src={model1} alt="Card image cap" />
              <div class="card-body model1name">
                <h5 class="card-title text-center text-light mt-2">
                  Card title 6
                </h5>
              </div>
            </div>
          </div>
        </div>
        </Carousel.Item>
        <Carousel.Item>
        <div className="row">
          <div className="col-lg-4 mt-5">
            <div class="card">
              <img class="card-img-top" src={model1} alt="Card image cap" />
              <div class="card-body model1name">
                <h5 class="card-title text-center text-light mt-2">
                  Card title 7
                </h5>
              </div>
            </div>

            <div class="card mt-5">
              <img class="card-img-top" src={model1} alt="Card image cap" />
              <div class="card-body model1name">
                <h5 class="card-title text-center text-light mt-2">
                  Card title 8
                </h5>
              </div>
            </div>
          </div>

          <div className="col-lg-4 mt-5">
            <div class="card">
              <img class="card-img-top" src={model1} alt="Card image cap" />
              <div class="card-body model1name">
                <h5 class="card-title text-center text-light mt-2">
                  Card title 9
                </h5>
              </div>
            </div>

            <div class="card mt-5">
              <img class="card-img-top" src={model1} alt="Card image cap" />
              <div class="card-body model1name">
                <h5 class="card-title text-center text-light mt-2">
                  Card title 10
                </h5>
              </div>
            </div>
          </div>

          <div className="col-lg-4 mt-5">
            <div class="card">
              <img class="card-img-top" src={model1} alt="Card image cap" />
              <div class="card-body model1name">
                <h5 class="card-title text-center text-light mt-2">
                  Card title 11
                </h5>
              </div>
            </div>

            <div class="card mt-5">
              <img class="card-img-top" src={model1} alt="Card image cap" />
              <div class="card-body model1name">
                <h5 class="card-title text-center text-light mt-2">
                  Card title 12
                </h5>
              </div>
            </div>
          </div>
        </div>
        </Carousel.Item>
        <Carousel.Item>
        <div className="row">
          <div className="col-lg-4 mt-5">
            <div class="card">
              <img class="card-img-top" src={model1} alt="Card image cap" />
              <div class="card-body model1name">
                <h5 class="card-title text-center text-light mt-2">
                  Card title 13
                </h5>
              </div>
            </div>

            <div class="card mt-5">
              <img class="card-img-top" src={model1} alt="Card image cap" />
              <div class="card-body model1name">
                <h5 class="card-title text-center text-light mt-2">
                  Card title 14
                </h5>
              </div>
            </div>
          </div>

          <div className="col-lg-4 mt-5">
            <div class="card">
              <img class="card-img-top" src={model1} alt="Card image cap" />
              <div class="card-body model1name">
                <h5 class="card-title text-center text-light mt-2">
                  Card title 15
                </h5>
              </div>
            </div>

            <div class="card mt-5">
              <img class="card-img-top" src={model1} alt="Card image cap" />
              <div class="card-body model1name">
                <h5 class="card-title text-center text-light mt-2">
                  Card title 16
                </h5>
              </div>
            </div>
          </div>

          <div className="col-lg-4 mt-5">
            <div class="card">
              <img class="card-img-top" src={model1} alt="Card image cap" />
              <div class="card-body model1name">
                <h5 class="card-title text-center text-light mt-2">
                  Card title 17
                </h5>
              </div>
            </div>

            <div class="card mt-5">
              <img class="card-img-top" src={model1} alt="Card image cap" />
              <div class="card-body model1name">
                <h5 class="card-title text-center text-light mt-2">
                  Card title 18
                </h5>
              </div>
            </div>
          </div>
        </div>
        </Carousel.Item>

				</Carousel>
      </div>
    </div>
  );
};

export default Modules;
