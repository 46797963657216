import "../Styles/globeview.css";
import warehouse from "../Assests/glbwarehouseicon.png"
import location from "../Assests/glblocationicon.png"
import application from "../Assests/glbapplicationicon.png"
import map from "../Assests/Map.svg"
import preglobebg from "../Assests/Preglobebg.png";

const Globeview = () => {
  return (
    <div className="container-fluid globe-crad-1">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 p-0 mt-5">
          <div className="card w-100">
            <div className="card-body preglobe p-0">
              <h2 className="text-center preh2 mt-5">Cost To Cost</h2>
              <h1 className="text-center preh1 mb-5">To Coverage</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid globe-crad-2 ">
        <div className="row">
          <div className="col-lg-5 col-md-4 col-sm-4 p-0">
            <div className="card globecrdlft mt-3">
              <div className="card-body p-5">
              
                <h3 className="mt-2 globetitle">
                  "Global Achievement <br /> Solutions"
                </h3>
                {/* icon1 */}
                <span> <img className="gwareicon" src={warehouse} alt="" /></span>
                <h4 className="text-center gh1">
                    <span className=""> Warehouse </span>
                    </h4>
                    <p className="text-center gpara1">Lorem ipsum dolor sit amet, consectetur 
                    adipiscing elit, sed do eiusmod tempor ut et dolore magna aliqua</p>
                    <div class="horizontal_dotted_line mt-4"></div>
                {/* icon1 */}
                    <br />
                 {/* icon2 */}
                 <span> <img className="gwareicon" src={location} alt="" /></span>
                <h4 className="text-center gh1">
                    <span className=""> Regions </span>
                    </h4>
                    <p className="text-center gpara1">Lorem ipsum dolor sit amet, consectetur 
                    adipiscing elit, sed do eiusmod tempor ut et dolore magna aliqua</p>
                    <div class="horizontal_dotted_line mt-4"></div>
                {/* icon2 */}
                <br />
                 {/* icon3 */}
                 <span> <img className="gwareicon" src={application} alt="" /></span>
                <h4 className="text-center gh1">
                    <span className=""> Applications </span>
                    </h4>
                    <p className="text-center gpara1">Lorem ipsum dolor sit amet, consectetur 
                    adipiscing elit, sed do eiusmod tempor ut et dolore magna aliqua</p>
                    
                {/* icon3 */}

              </div>
            </div>
          </div>
          <div className="col-lg-7 col-md-8 col-sm-8 p-0 mt-3">
            <div className="card globecrdrgt ">
              <div className="card-body">
               <img className="gmap" src={map} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Globeview;
