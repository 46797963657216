   // App.js
   import React from 'react';
   import Preenav from './Component/Preenav';
   import Midnav from './Component/Midnav';
   import Navbar from './Component/Navbar';
   import Hero from './Component/Hero';
   import RealCount from './Component/RealCount';
   import Carousel from './Component/Carousel';
   import Warehouse from './Component/Warhouse';
   import About from './Component/About'
   import Modules from './Component/Moduls'
   import Globeview from './Component/Globeview'
   import Partners from './Component/Partners'
   import Partners2 from './Component/Partner2';
   import Partners3 from './Component/Partner3';
   import Ourprojects from './Component/Ourproject'
   import Team from './Component/Team'
   import News from './Component/News'
   import Toggle from './Component/Togle'
   import Footer from './Component/Footer'
  //  import PreLoder from './Component/Loader/PreenavLoader'
   import './App.css';
   import 'bootstrap/dist/css/bootstrap.min.css';
  //  import 'primeflex/primeflex.css';  
  //  import 'primereact/resources/primereact.css';
  //   import 'primereact/resources/themes/lara-light-indigo/theme.css';
    
   
        



   function App() {
     return (
       <>
         <Preenav />
         {/* <Midnav/> */}
         <Navbar/>
         <Hero/>
         <RealCount/>
         <Carousel/>
         <About/>
         <Warehouse/>
         <Globeview/>
         <Modules/>
         <Partners/>
         <Partners2/>
         <Partners3/>
         <Ourprojects/>
         <Team/>
         <News/>
         <br />
         <br />
         {/* <Toggle/> */}
         <Footer/>
         {/* <PreLoder/> */}
       </>
       
     );
   }

   export default App;