import "../Styles/about.css";
import abrtimg from "../Assests/Aboutrghtimg.png";
import doneicn from "../Assests/done.svg";

const About = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xs-6 col-xl-6 col-xxl-6 col-lg-6 col-md-6 col-sm-6 p-0">
          <div class="card mt-5 aboutlftcrd p-5 abtbgimg">
            <div class="card-body">
              <h5 class="abt-title mt-5">- About Phoenix Industry</h5>
              <h2 class="abt-subtitle mt-2">Our Platform Mission</h2>
              <p class="aboutpara1 mt-4">
                Etiam porta sem malesuada magna mollis euismod. <br /> Vivamus
                sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.{" "}
                <br /> Maecenas faucibus mollis interdum. Donec id elit non mi
                porta gravida at eget metus.
              </p>
              <div class="horizontal_dotted_line mt-5"></div>
              <div class="row">
                <div class="col-6">
                  <h6 className="mt-4 abtltxt1">
                    <span className="">
                      <img className="doneicn" src={doneicn} alt="" />
                    </span>
                    Unforgettable Experience
                  </h6>
                  <p className="Apara1 mt-4">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Iure corporis enim animi itaque consectetur necessitatibus
                  </p>
                </div>
                <div class="col-6">
                  <h6 className="mt-4 abtRtxt2">
                    <span className="">
                      <img className="doneicn" src={doneicn} alt="" />
                    </span>
                    Unforgettable Experience
                  </h6>
                  <p className="Apara1 mt-4">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Iure corporis enim animi itaque consectetur necessitatibus
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xs-6 col-xl-6 col-xxl-6 col-lg-6 col-md-6 col-sm-6 p-0">
          <img className="abtimg2" src={abrtimg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default About;
