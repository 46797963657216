import "../Styles/news.css";
import factory from "../Assests/factoryicn.png"
import device from "../Assests/deviceicn.png"
import hand from "../Assests/handicn.png"
import arrow from "../Assests/right_arrow.svg";

const News = () => {
  return (
    <div className="container-fluid newscontainer">
      <div className="row">
        <div className="col-lg-12">
          <h5 className="text-center newsh1">- Latest News</h5>
          <h2 className="text-center newsh2">Check out our Inside Stories</h2>
          </div>
          <div className="col-xs-6 col-xl-6 col-xxl-6 col-lg-6 col-md-6 col-sm-6 mt-5">
            <div class="card newsbgimg1">
              <div class="card-body">
                <h3 class="text-center text-light news1h1">
                    <span><img className="factoryicn" src={factory} alt="" /></span> <br />
                    Explore What Happening <br />
                    Across Plants
                </h3>
                </div>
            </div>
            </div>
          <div className="col-xs-6 col-xl-6 col-xxl-6 col-lg-6 col-md-6 col-sm-6 mt-5">
            <div class="card newsbgimg2">
              <div class="card-body">
                <h3 class="text-center text-light news2h1">
                <span><img className="factoryicn" src={device} alt="" /></span> <br />
                Explore New Technology <br /> And Devices
                </h3>
                </div>
            </div>

            <div class="card newsbgimg3 mt-3">
              <div class="card-body">
                <h3 class="text-center text-light news3h1">
                <span><img className="factoryicn" src={hand} alt="" /></span> <br />
                Explore What Happening <br /> Across Business & Solution
                </h3>
                </div>
            </div>
          </div>
        
      </div>
    </div>
  );
};

export default News;
