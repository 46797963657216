import "../Styles/ourprojects.css";
import arrow from "../Assests/right_arrow.svg";
import arrowblack from "../Assests/right_arrow_black1.svg";
import weber from "../Assests/weberprojimg-1.png";
 
const Ourproject = () => {
  return (
    <div className="container-fluid ">
      <div className="row col ">
        <div className="col-lg-4 p-0">
          <div className="card projcrd ">
            <div className="card-body prjcrdbdy p-5">
              <h5 className="protitle">- Phoenix Feature Product</h5>
              <h1 className="prjh1">Our Projects</h1>
              <p className="projpara">
                Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Etiam
                fermentum nulla. Lorem ipsum dolor sit amet.
              </p>
              <a className="prjob" href="">
                <p className="pb1 text-center">
                  Know More
                  <span className="pic1">
                    <img className="" src={arrow} alt="" />
                  </span>
                </p>
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-4 p-0">
          <div className="card projcrd1">
            <div className="card-body prjcrdbdy1"></div>
          </div>
        </div>
 
        <div className="col-lg-4 p-0">
          <div className="card projcrd3">
            <div className="card-body prjcrdbdy2 p-5 mt-4">
              <h1 className="prjh3 text-center">Weber</h1>
              <p className="projpara3 text-center">
                Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Etiam
                fermentum nulla. Lorem ipsum dolor sit amet.
              </p>
              <a className="prjob3" href="">
                <p className="pb3 text-center">
                  Know More
                  <span className="pic1">
                    <img className="weberarr" src={arrowblack} alt="" />
                  </span>
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
 
      {/* row2 */}
      <div className="row col ">
        <div className="col-lg-3 p-0">
          <div className="card projcrdr2">
            <div className="card-body prjcrdbdy p-5">
              <h1 className="prjh1r2 text-center mt-4">Glass</h1>
              <p className="projparar2 text-center">
                Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Etiam
                fermentum nulla. Lorem ipsum dolor sit amet.
              </p>
              <a className="prjobr2" href="">
                <p className="pb1r2 text-center">
                  Know More
                  <span className="pic1">
                    <img className="" src={arrow} alt="" />
                  </span>
                </p>
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-3 p-0">
          <div className="card projcrdr3">
            <div className="card-body prjcrdbdy1"></div>
          </div>
        </div>
 
        <div className="col-lg-3 p-0">
          <div className="card projcrdr4">
            <div className="card-body prjcrdbdy2 p-5 mt-4">
              <h1 className="prjh3r2 text-center">Solar Glass</h1>
              <p className="projparar4 text-center">
                Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Etiam
                fermentum nulla. Lorem ipsum dolor sit amet.
              </p>
              <a className="prjob3" href="">
                <p className="pb4 text-center">
                  Know More
                  <span className="pic1">
                    <img className="" src={arrow} alt="" />
                  </span>
                </p>
              </a>
            </div>
          </div>
        </div>
 
        <div className="col-lg-3 p-0">
          <div className="card projcrd5">
            <div className="card-body prjcrdbdy1"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
 
export default Ourproject;
 
 