import react from "react";
import Highlight1 from '../Assests/HighlightEffect1.svg'
import Highlight2 from '../Assests/HighlightEffect2.svg'
import realcntbg from '../Assests/realcountbg.png'
import '../Styles/realcount.css'


const RealCount = () => {
    return (
      <div className="container-fluid countbg">
        <div className="row justify-content-center">
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 mt-3 cnt1 text-center">
                <span className="text-1"> 15+ </span> <br/> <span color="st-1"> Years </span>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 mt-3 cnt2 text-center">
                 <span className="text-2"> 35+ </span> <br/><span className="st-2"> Plants </span>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 mt-3 cnt3 text-center">
                <span className="text-3"> 110+ </span> <br/>  <span className="st-3"> Projects </span>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 mt-3 cnt4 text-center">
               <span className="text-4">  200+ </span> <br/> <span className="st-4"> Workforces </span>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 mt-3 cnt5 text-center">
               <span className="text-5">  15000+  </span> <br/> <span className="st-5">Production </span>
            </div>
           
        </div>
      </div>
    );
  };
  
  export default RealCount;